
import { Component, Vue } from 'vue-property-decorator'
import echarts from 'echarts'
import { WaterMeterDetail } from '@/types/waterMeter'
interface TrafficDetail {
  collectTime: string;
  enterNum: string;
}

let enterNumChart: echarts.ECharts
let forecastChart: echarts.ECharts
@Component({
  name: 'Analyse'
})
export default class Analyse extends Vue {
  private searchInfo: { dateRange: Array<string> } = {
    dateRange: []
  }

  private activeName = 'history'
  private type = 'chart'
  private historyInfo = {
    enterNum: '',
    avg: '',
    max: '',
    min: ''
  }

  private selectTime = 0
  private pickerOptions = {
    // 选中日期后会执行的回调
    onPick: (date: {maxDate: any; minDate: any}) => {
      this.selectTime = date.minDate.getTime()
      if (date.maxDate) {
        this.selectTime = 0
      }
    },
    disabledDate: (time: any) => {
      if (this.selectTime !== 0) {
        const one = 365 * 24 * 3600 * 1000
        const minTime = this.selectTime - one
        const maxTime = this.selectTime + one
        return time.getTime() >= (Date.now() - 24 * 3600 * 1000) || (time.getTime() < minTime || time.getTime() > maxTime)
      } else {
        return time.getTime() >= (Date.now() - 24 * 3600 * 1000)
      }
    }
  }

  private loading = false
  private dataList: Array<TrafficDetail> = []
  private page = 1
  private size = 10
  private total = 0
  private info = {
    avgUpPer: '',
    forecastData: '',
    realData: ''
  }

  get projectId () {
    return this.$route.params.projectId || ''
  }

  created () {
    if (this.projectId) {
      this.initTime()
      this.onSearch()
    } else {
      this.$router.push({ path: '/realtimeTrafficList' })
    }
  }

  mounted () {
    window.addEventListener('resize', this.resize)
  }

  destroyed () {
    window.removeEventListener('resize', this.resize)
  }

  avgDeviation (enterNum: string, avg: string) {
    const num = enterNum ? avg ? (Math.round(Number(enterNum) * 100) - Math.round(Number(avg) * 100)) / 100 : enterNum : '--'
    const value = num === '--' || num === 0 ? '<span>--</span>' : num > 0 ? `<span style="color: #FF603B">+${num}</span>` : `<span style="color: #52C41A">${num}</span>`
    return value
  }

  resize () {
    if (enterNumChart) {
      enterNumChart.resize()
    }
    if (forecastChart) {
      forecastChart.resize()
    }
  }

  initTime () {
    const date = new Date()
    const date1 = new Date(date.getTime() - 24 * 60 * 60 * 1000)
    const date2 = new Date(date.getTime() - 30 * 24 * 60 * 60 * 1000)
    const val = date1.getFullYear() + '-' + this.addZero(date1.getMonth() + 1) + '-' + this.addZero(date1.getDate())
    const val2 = date2.getFullYear() + '-' + this.addZero(date2.getMonth() + 1) + '-' + this.addZero(date2.getDate())
    this.searchInfo.dateRange = [val2, val]
  }

  addZero (val: number) {
    return val < 10 ? '0' + val : String(val)
  }

  handleClick () {
    if (this.activeName === 'history') {
      this.onSearch()
    } else {
      this.getForecastData()
    }
  }

  changeType (type: string) {
    this.type = type
    this.onSearch()
  }

  getStatistic (): void {
    this.$axios.get(this.$apis.realtimeTraffic.selectPassengerData, {
      projectId: this.projectId,
      startTime: this.searchInfo.dateRange[0],
      endTime: this.searchInfo.dateRange[1]
    }).then((res: { enterNum: string; avg: string; max: string; min: string }) => {
      this.historyInfo = {
        enterNum: res.enterNum,
        avg: res.avg,
        max: res.max,
        min: res.min
      }
    })
  }

  getDataChart (): void {
    this.$axios.get(this.$apis.realtimeTraffic.selectPassengerDataChart, {
      projectId: this.projectId,
      startTime: this.searchInfo.dateRange[0],
      endTime: this.searchInfo.dateRange[1]
    }).then((res: { passengerCountsTimeList: Array<string>; passengerCountsList: Array<string>; avg: string }) => {
      const len = res.passengerCountsTimeList ? res.passengerCountsTimeList.length : 0
      const avgList = len > 0 ? new Array(len).fill(res.avg) : []
      this.draw(res.passengerCountsTimeList || [], res.passengerCountsList || [], avgList)
    })
  }

  getData (): void {
    this.loading = true
    this.$axios.get(this.$apis.realtimeTraffic.selectPassengerDataList, {
      projectId: this.projectId,
      startTime: this.searchInfo.dateRange[0],
      endTime: this.searchInfo.dateRange[1],
      page: this.page,
      size: this.size
    }).then((res: WaterMeterDetail) => {
      this.total = res.total || 0
      this.dataList = res.list || []
    }).finally(() => {
      this.loading = false
    })
  }

  onSearch () {
    this.page = 1
    if (this.searchInfo.dateRange && this.searchInfo.dateRange[0]) {
      this.getStatistic()
      if (this.type === 'chart') {
        this.getDataChart()
      } else {
        this.getData()
      }
    } else {
      this.$message.error('请选择查询日期范围')
    }
  }

  draw (xData: Array<string>, yData1: Array<string>, yData2: Array<string>) {
    this.$nextTick(() => {
      const option: any = {
        tooltip: {
          trigger: 'axis'
        },
        grid: {
          top: 80,
          left: 10,
          right: 30,
          bottom: 0,
          containLabel: true
        },
        legend: {
          top: 30,
          left: 0,
          show: true,
          type: 'plain',
          itemGap: 25,
          itemWidth: 18,
          icon: 'path://M0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z'
        },
        title: {
          text: '客流分析',
          textStyle: {
            fontSize: 16,
            fontWeight: 600,
            color: '#000000'
          }
        },
        backgroundColor: 'transparent',
        color: ['#2C8EFF', '#1AD1B0'],
        xAxis: {
          type: 'category',
          axisTick: {
            alignWithLabel: {
              boundaryGap: true
            }
          },
          axisLine: {
            lineStyle: {
              color: '#DDDDDD'
            }
          },
          axisLabel: {
            show: true,
            margin: 15,
            textStyle: {
              color: '#999999'
            }
          },
          data: xData
        },
        yAxis: {
          type: 'value',
          minInterval: 1,
          axisLine: {
            show: false,
            lineStyle: {
              color: 'rgba(0, 0, 0, 0.45)'
            }
          },
          axisTick: {
            show: false
          },
          splitLine: {
            lineStyle: {
              color: 'rgba(0, 0, 0, 0.15)'
            }
          }
        },
        series: [{
          name: '客流人次',
          type: 'line',
          symbolSize: 0,
          smooth: true,
          lineStyle: {
            width: 4,
            shadowBlur: 0,
            shadowColor: 'rgba(44, 142, 255, 0.2)',
            shadowOffsetY: 20
          },
          data: yData1
        }, {
          name: '日均客流人次',
          type: 'line',
          symbolSize: 0,
          smooth: true,
          lineStyle: {
            width: 2,
            type: 'dashed'
          },
          data: yData2
        }]
      }
      enterNumChart = echarts.init(this.$refs.historyEchart as any)
      enterNumChart.setOption(option)
    })
  }

  getForecastData (): void {
    this.$axios.get(this.$apis.realtimeTraffic.selectPassengerForecast, {
      projectId: this.projectId,
      type: this.activeName === 'week' ? '0' : '1'
    }).then(res => {
      this.info = {
        avgUpPer: res.avgUpPer,
        forecastData: res.forecastData,
        realData: res.realData
      }
      this.drawCharts(res.xCollectTimes || [], res.yDatas || [], res.yUpPers || [])
    })
  }

  // 折线图
  drawCharts (xData: Array<string>, yData1: Array<string>, yData2: Array<string>) {
    this.$nextTick(() => {
      const len = yData1.length
      const ydataforecast = len > 0 ? new Array(len).fill('').fill(this.info.forecastData, len - 1, len) : [this.info.forecastData]
      const option: any = {
        tooltip: {
          trigger: 'axis',
          formatter: function (params: Array<{ name: string; seriesName: string; data: any }>) {
            let str = ''
            params.forEach((item, i) => {
              if (i === 0) {
                str += `${item.name}<br/>${item.seriesName}：<span>${item.data}</span>人次<br/>`
              } else if (i === 2) {
                str += `${item.seriesName}：<span>${item.data}</span>%<br/>`
              } else {
                str += item.data ? `${item.seriesName}：<span>${item.data}</span>人次<br/>` : ''
              }
            })
            return str
          }
        },
        grid: {
          top: 30,
          left: 0,
          right: 30,
          bottom: 0,
          containLabel: true
        },
        backgroundColor: 'transparent',
        xAxis: {
          type: 'category',
          axisTick: {
            alignWithLabel: {
              boundaryGap: true
            }
          },
          axisLine: {
            lineStyle: {
              color: 'rgba(0, 0, 0, 0.45)'
            }
          },
          axisLabel: {
            show: true,
            margin: 15,
            textStyle: {
              color: 'rgba(0, 0, 0, 0.45)'
            }
          },
          data: xData
        },
        yAxis: [{
          name: '客流人次',
          type: 'value',
          offset: -2,
          minInterval: 1,
          axisLine: {
            show: false
          },
          axisLabel: {
            interval: 0,
            formatter: '{value} 人次'
          },
          axisTick: {
            show: false
          },
          splitLine: {
            lineStyle: {
              color: 'rgba(0, 0, 0, 0.15)'
            }
          }
        }, {
          name: '增长率',
          type: 'value',
          offset: -2,
          axisLine: {
            show: false
          },
          axisLabel: {
            interval: 0,
            formatter: '{value} %'
          },
          axisTick: {
            show: false
          },
          splitLine: {
            lineStyle: {
              color: 'rgba(0, 0, 0, 0.15)'
            }
          }
        }],
        series: [{
          name: '客流人次',
          type: 'bar',
          yAxisIndex: 0,
          barGap: '0',
          color: '#2C8EFF',
          barMaxWidth: 32,
          itemStyle: {
            shadowBlur: 6,
            shadowColor: 'rgba(44, 142, 255, 0.2)',
            shadowOffsetX: 6
          },
          data: yData1
        }, {
          name: '预测客流人次',
          type: 'bar',
          yAxisIndex: 0,
          barGap: '0',
          color: 'rgba(125, 39, 255, 0.4)',
          barMaxWidth: 32,
          itemStyle: {
            borderWidth: 1,
            borderType: 'dashed',
            borderColor: 'rgba(125, 39, 255, 1)'
          },
          data: ydataforecast
        }, {
          name: '增长率',
          type: 'line',
          yAxisIndex: 1,
          color: '#36CBCB',
          symbolSize: 10,
          smooth: true,
          lineStyle: {
            width: 4,
            shadowBlur: 0,
            shadowColor: 'rgba(54, 203, 203, 0.2)',
            shadowOffsetY: 20
          },
          data: yData2
        }]
      }
      forecastChart = echarts.init((this.$refs.chart as any))
      forecastChart.setOption(option)
    })
  }
}
